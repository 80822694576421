html, body {
  height: 100%;
}
#page-content {
  flex: 1 0 auto;
}

.Footer-color{
background-color:rgba(40, 40, 40); 
color:rgb(204, 204, 204);
}

@media (max-width: 1200px) {
  .carousel {
    max-width: 1200px;
    width:auto;
    height:auto;
  }
}

:root {
  --primary: #fea116;
  --light: #f1f8ff;
  --dark: #0f172b;
}

.ff-secondary {
  font-family: 'Pacifico', cursive;
}

.fw-medium {
  font-weight: 600 !important;
}

.fw-semi-bold {
  font-weight: 700 !important;
}

.back-to-top {
  position: fixed;
  display: none;
  right: 45px;
  bottom: 45px;
  z-index: 99;
}

/*** Button ***/
.btn {
  font-family: 'Nunito', sans-serif;
  font-weight: 500;
  text-transform: uppercase;
  transition: 0.5s;
}

.btn.btn-primary,
.btn.btn-secondary {
  color: #ffffff;
}

.btn-square {
  width: 38px;
  height: 38px;
}

.btn-sm-square {
  width: 32px;
  height: 32px;
}

.btn-lg-square {
  width: 48px;
  height: 48px;
}

.btn-square,
.btn-sm-square,
.btn-lg-square {
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: normal;
  border-radius: 2px;
}

/*** Service ***/
.service-item {
  box-shadow: 0 0 45px rgba(0, 0, 0, 0.08);
  transition: 0.5s;
}

.service-item:hover {
  background: var(--primary);
}

.service-item * {
  transition: 0.5s;
}

.service-item:hover * {
  color: var(--light) !important;
}
/*** Social icons Horizontal ***/
.horizontalNoBullets {
  display: flex;
  list-style: none; 
}

.horizontalNoBullets li {
  margin-right: 20px; 
}

/*** Social icons Horizontal ***/
.noBullets {
  list-style: none; 
}

.noBullets li {
  margin-right: 20px; 
}
/*** Sign out **/
button.signout {
  padding: 1px 6px;
  border: none;
  border-radius: 0px;
  background-color: transparent;
  background-repeat: no-repeat;
  cursor: pointer;
  overflow: hidden;
  outline: none;
}
.NavLinkSignout {
  text-decoration: underline;
}